import React, { ComponentType, PureComponent, FunctionComponent, useContext } from "react";
import { Authentication } from "./authentication";
import { CognitoRefreshToken, CognitoUser } from "amazon-cognito-identity-js";
import { AUTH_STATE, CognitoUserWithChallenge, isChallengedUser } from "./authentication.types";
import { UnreachableCaseError } from "../../utils";
import { fetchUserInformation, patchUserInformation, logOut } from "../api";
import config from "../../config";
import { HubCallback, HubClass } from "@aws-amplify/core/lib/Hub";
import { unsafeErrorToError } from "../../utils/error";

export const AuthenticationContext = React.createContext({} as AuthenticationProps["auth"]);

export type AdLoginProvider = "admin" | "user";

interface AuthenticationProviderState {
  state: AUTH_STATE;
  userInformation?: API.IUserInformation;
  user?: CognitoUser | CognitoUserWithChallenge;
  userAttributes?: {
    [key: string]: any;
  };
  error?: Error;
  temporaryPassword?: string;
}

export interface AuthenticationActions {
  logout: () => Promise<void>;
  login: (username: string, password: string) => Promise<void>;
  logoutAski: () => Promise<void>;
  loginAski: (username: string, password: string) => Promise<void>;
  completeNewPassword: (newPassword: string) => Promise<void>;
  forgotPassword: (newPassword: string) => Promise<void>;
  forgotPasswordSubmit: (email: string, code: string, newPassword: string) => Promise<void>;
  changePassword: (oldPassword: string, newPassword: string) => Promise<void>;
  updateUserInfo: () => Promise<void>;
  currentUser: () => Promise<any>;
  loginViaAD: (providerType: AdLoginProvider) => Promise<void>;
  patchUserInformation: (information: API.IUserInformationRequest) => Promise<void>;
  refreshSession: () => Promise<void>;
}

export interface AuthenticationProps {
  auth: AuthenticationProviderState & { actions: AuthenticationActions };
}

export class AuthenticationProvider extends PureComponent<
  { authModule: typeof Authentication; hubModule: HubClass },
  AuthenticationProviderState
> {
  state: AuthenticationProviderState = {
    state: AUTH_STATE.LOADING
  };

  hasMounted = false;

  isAski: Boolean = window.location.pathname.startsWith("/asiakirjat");

  private readonly handleEvents: HubCallback = async data => {
    switch (data.payload.event) {
      case "signIn":
      case "configured":
        return this.currentUser();

      case "signIn_failure":
        return this.setErrorStatus(new Error(data.payload.data));
      case "cognitoHostedUI_failure":
      case "customState_failure":
        return this.setErrorStatus(
          new Error(new URL(window.location.href).searchParams.get("error_description") || "Error signing in!")
        );
      case "parsingCallbackUrl":
      case "forgotPassword":
      case "tokenRefresh":
        return undefined;
      default:
        console.log(`Unhandled event ${data.payload.event}`, data);

        return undefined;
    }
  };

  constructor(props: any) {
    super(props);
    this.props.hubModule.listen("auth", this.handleEvents);
  }

  componentDidMount() {
    this.hasMounted = true;
  }

  componentWillUnmount() {
    this.hasMounted = false;
    this.props.hubModule.remove("auth", this.handleEvents);
  }

  readonly updateState = (update: (s: Readonly<AuthenticationProviderState>) => Partial<AuthenticationProviderState>) => {
    if (this.hasMounted) {
      this.setState(s => ({
        ...s,
        ...update(s),
        temporaryPassword: update(s)?.temporaryPassword || undefined // ensure that temporary password is always unset
      }));
    }
  };

  readonly currentUser = async () => {
    try {
      // Force refresh of the current session, so that userInfo is fetched correctly
      await this.props.authModule.currentSession();
      const info = await this.props.authModule.currentUserInfo();
      const userInfo = !this.isAski ? await fetchUserInformation() : undefined;

      this.updateState(s => ({
        state: AUTH_STATE.SIGNED_IN,
        userAttributes: info.attributes,
        error: undefined,
        userInformation: userInfo?.data ?? undefined
      }));
    } catch (e) {
      console.error(e);
      this.updateState(s => ({ state: AUTH_STATE.SIGN_IN }));
    }
  };

  readonly resetState = () => {
    this.updateState(s => ({
      state: AUTH_STATE.SIGN_IN,
      userInformation: undefined,
      user: undefined,
      tokens: undefined,
      userAttributes: undefined,
      error: undefined,
      temporaryPassword: undefined
    }));
  };

  readonly setErrorStatus = (error: any) => {
    console.error(error);

    return this.updateState(s => ({ error: unsafeErrorToError(error) }));
  };

  readonly loginViaAD = async (providerType: AdLoginProvider) => {
    const provider = providerType === "admin" ? config.cognito.adminIdentityProvider : config.cognito.userIdentityProvider;

    console.log(this.props.authModule);

    await this.props.authModule.federatedSignIn({
      customProvider: provider
    });
  };
  readonly setChallengeStatus = (user: CognitoUserWithChallenge, temporaryPassword: string) => {
    switch (user.challengeName) {
      case "NEW_PASSWORD_REQUIRED":
        return this.updateState(s => ({
          state: AUTH_STATE.NEW_PASSWORD,
          user,
          userAttributes: user.challengeParam.userAttributes,
          temporaryPassword
        }));
      case "SMS_MFA":
      case "SOFTWARE_TOKEN_MFA":
        return console.warn("MFA Token challenge has not been implemented!");
      case "MFA_SETUP":
        return console.warn("MFA Setup challenge has not been implemented");
      default:
        throw new UnreachableCaseError(user.challengeName);
    }
  };

  readonly updateUserInfo = async () => {
    const userInfo = await fetchUserInformation();
    this.updateState(s => ({
      userInformation: userInfo.data
    }));
  };

  readonly forgotPassword = async (email: string) => {
    this.updateState(s => ({
      error: undefined
    }));

    try {
      await this.props.authModule.forgotPassword(email);
    } catch (e) {
      console.log("error in forgot pw!", e);
      this.updateState(s => ({
        error: new Error(JSON.stringify(e))
      }));
    }
  };

  readonly forgotPasswordSubmit = async (email: string, code: string, newPassword: string) => {
    this.updateState(s => ({
      error: undefined
    }));

    try {
      await this.props.authModule.forgotPasswordSubmit(email, code, newPassword);
    } catch (e) {
      console.log("error in forgot pw submit!", e);

      this.updateState(s => ({
        error: new Error(JSON.stringify(e))
      }));
    }
  };

  readonly login = async (username: string, password: string) => {
    this.updateState(s => ({ state: AUTH_STATE.LOADING, error: undefined }));
    try {
      const user: CognitoUser | CognitoUserWithChallenge = await this.props.authModule.signIn(username, password);

      if (isChallengedUser(user)) {
        this.setChallengeStatus(user, password);
      }
    } catch (e) {
      this.setErrorStatus(e);
      this.updateState(s => ({ state: AUTH_STATE.SIGN_IN }));
    }
  };

  readonly loginAski = async () => {
    this.updateState(s => ({ state: AUTH_STATE.LOADING, error: undefined }));
    await this.props.authModule.federatedSignIn();
  };

  private readonly doRefreshSession = (user: CognitoUser, refreshToken: CognitoRefreshToken): Promise<void> =>
    new Promise((resolve, reject) => {
      user.refreshSession(refreshToken, (err, result) => {
        if (err) {
          reject(new Error(`Error while refreshing session, error: ${err}`));
        } else {
          resolve();
        }
      });
    });

  readonly refreshSession = async () => {
    const user: CognitoUser = await Authentication.currentAuthenticatedUser();

    if (!user) {
      throw new Error("Error while refreshing session, user not found.");
    }

    const session = await Authentication.currentSession();
    const token = session.getRefreshToken().getToken();

    if (!token) {
      throw new Error("Error while refreshing session, cannot get refresh token!");
    }

    const refreshToken: CognitoRefreshToken = new CognitoRefreshToken({ RefreshToken: token });

    try {
      await this.doRefreshSession(user, refreshToken);
    } catch (e) {
      if (typeof e === "string") {
        throw new Error(e);
      } else if (e instanceof Error) {
        throw e;
      } else {
        console.error(e);
        throw new Error("Unknown error");
      }
    }
  };

  readonly completeNewPassword = async (newPassword: string) => {
    this.updateState(s => ({ state: AUTH_STATE.LOADING, error: undefined }));
    try {
      await this.props.authModule.completeNewPassword(this.state.user, newPassword, {});
      this.currentUser();
    } catch (e) {
      console.log("error in completeNewPassword!", e);

      this.setErrorStatus(e);
      this.updateState(s => ({ state: AUTH_STATE.SIGN_IN }));
    }
  };

  readonly changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      const user = await this.props.authModule.currentAuthenticatedUser();
      await this.props.authModule.changePassword(user, oldPassword, newPassword);
      await this.logout();
    } catch (e) {
      console.log("error in changePassword!", e);

      this.setErrorStatus(e);
    }
  };

  readonly patchUserInformation = async (information: API.IUserInformationRequest) => {
    const userInfo = await patchUserInformation(information);

    this.updateState(s => ({
      userInformation: userInfo.data
    }));
  };

  logoutAski = async () => {
    // Invalidate refresh token first
    // This way attacker won't be able to get a new access token between access token invalidation and refresh token invalidation
    try {
      await this.props.authModule.signOut({ global: true });
    } catch (e) {
      console.error(e);
      // Global sign out will throw an error if the access token has already been revoked on another device
      // When this happens, just do a regular sign out to remove any tokens stored in local storage etc.
      await this.props.authModule.signOut();
    }

    console.log(this.props.authModule.currentSession());
    this.resetState();
  };

  logout = async () => {
    // Remove all session specific local storage items
    const currentUserKey = `CognitoIdentityServiceProvider.${config.cognito.userPoolWebClientId}.LastAuthUser`;
    const userId = window.localStorage[currentUserKey];

    Object.keys(window.localStorage).forEach(key => {
      if (key.startsWith(userId)) {
        window.localStorage.removeItem(key);
      }
    });
    // Grab access token first since it won't be available later
    const accessToken = (await this.props.authModule.currentSession()).getAccessToken().getJwtToken();

    if (accessToken && !this.isAski) {
      await logOut(accessToken);
    }

//    this.resetState();

    try {  // This part of logout has to be last, because it apparently triggers a redirect which will cause the rest of the code to not run
      await this.props.authModule.signOut({ global: true });
    } catch (e) {
      console.error(e);
      // Global sign out will throw an error if the access token has already been revoked on another device
      // When this happens, just do a regular sign out to remove any tokens stored in local storage etc.
      await this.props.authModule.signOut();
    }
  };

  render() {
    const value: AuthenticationProps["auth"] = {
      ...this.state,
      actions: {
        login: this.login,
        loginAski: this.loginAski,
        logoutAski: this.logoutAski,
        logout: this.logout,
        completeNewPassword: this.completeNewPassword,
        updateUserInfo: this.updateUserInfo,
        currentUser: this.currentUser,
        loginViaAD: this.loginViaAD,
        forgotPassword: this.forgotPassword,
        forgotPasswordSubmit: this.forgotPasswordSubmit,
        changePassword: this.changePassword,
        patchUserInformation: this.patchUserInformation,
        refreshSession: this.refreshSession
      }
    };

    return <AuthenticationContext.Provider value={value}>{this.props.children}</AuthenticationContext.Provider>;
  }
}

type WithAuthenticationType = <P>(Comp: ComponentType<P & AuthenticationProps>) => FunctionComponent<P>;

export const withAuthentication: WithAuthenticationType = Comp => props => (
  <AuthenticationContext.Consumer>{authValues => <Comp auth={authValues} {...props} />}</AuthenticationContext.Consumer>
);

export const useUserId = () => {
  const { userInformation } = useContext(AuthenticationContext);

  return userInformation?.userId;
};
